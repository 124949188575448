.PPT-Generator-Upload {
  border: 1px solid #eceaea;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 31vh;
  margin-bottom: 10px;
}

.textArea {
  padding: 5px 15px 5px;
  font-weight: 400;
}

.textarea.level {
  margin-top: 10px;
}
