.Patient-Narration-Upload {
  border: 1px solid #eceaea;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 145px;
  margin-bottom: 10px;
}

.Patient-Narration-Checkbox {
  border: 1px solid #eceaea;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  margin-bottom: 7px;
}

.answer-content {
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  min-height: 150px;
  word-wrap: break-word;
}

.GeneratedTitle {
  margin-top: 0;
  margin-bottom: 10px;
}
