.SAP-Insights-Upload {
  border: 1px solid #f5f1f1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 55vh;
  margin-bottom: 10px;
}

.question {
  cursor: pointer;
  padding: 5px;
  border: 1px solid #eceaea;
  border-radius: 5px;
  margin-bottom: 10px;
}

.question:hover {
  background-color: #e0f2fe;
}

.question.selected {
  background-color: #e0f2fe;
}
