.specific_page .generate-form-button {
  width: 50%;
  /* background-color: rgb(89, 91, 250); */
  margin-top: 10px;
  font-weight: 500;
  font-size: 50px;
}

.specific_page .data-highcharts-chart div[data-highcharts-chart] {
  height: 100%;
}

.specific_page .ant-layout.ant-layout-has-sider {
  height: 100vh;
}

.specific_page .ant-layout-sider-children {
  background: white;
}
/* :where(.css-dev-only-do-not-override-2i2tap).ant-layout .ant-layout-sider {
  background: rgb(0 21 41);
}  */

.specific_page .ant-menu-item.ant-menu-item-selected {
  background-image: linear-gradient(310deg, rgb(37 92 154) 0%, #3388ff 100%);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  opacity: 0.95;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 5px 10px -3px,
    rgba(0, 0, 0, 0.25) 0px 6px 10px -5px;
}

.specific_page .QuickAccessMemuLable {
  background-image: linear-gradient(310deg, rgb(20 39 34) 0%, #00a88f 100%);
  display: inline-block;
  margin: 0;
  font-size: 13px;
  background-size: cover;
  background-position: center;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.specific_page .ant-divider.small {
  font-size: 0.8rem;
  margin: 10px 0;
}

.specific_page .ant-btn.btn-1 {
  margin-top: 15px;
  width: 100%;
  background-image: linear-gradient(310deg, rgb(49 147 121) 0%, #00a88f 100%);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  opacity: 0.95;
  box-shadow: rgba(0, 0, 0, 0.23) 0px 5px 10px -3px,
    rgba(0, 0, 0, 0.25) 0px 6px 10px -5px;
}

.specific_page .ant-layout-content {
  margin: 0 !important;
  background: transparent !important;
  overflow: auto;
}

.specific_page .shadowEeffect {
  border-color: transparent;
  box-shadow: 0 1px 0px -1px rgba(0, 0, 0, 0.16),
    0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 6px 4px rgba(0, 0, 0, 0.09);
}

.specific_page .QuickLinks {
  margin: 5px 1px 10px 0px;
  color: blue;
  cursor: pointer;
}

.specific_page
  .ant-timeline
  .ant-timeline-item-last
  > .ant-timeline-item-content {
  min-height: 30px;
}

.specific_page .ant-card .ant-card-head {
  min-height: 45px;
}

.specific_page .ant-layout {
  background: #efefef;
}

.specific_page .ant-layout-2.ant-layout-content {
  background: #f8f9fb !important;
}

.specific_page .loginPoweredBy {
  position: absolute;
  left: 10px;
  bottom: 0;
  font-size: 0.8rem;
  color: #2661a68f;
}

.specific_page .site-description-item-profile-p-label {
  margin-bottom: 0px;
  margin-top: 5px;
  font-weight: 600;
}

.specific_page .personalDetails {
  margin-bottom: 15px !important;
}

.specific_page .ant-float-btn-body {
  background-image: linear-gradient(310deg, rgb(20 39 34) 0%, #00a88f 100%);
  color: rgb(255, 255, 255);
  box-shadow: 1px 1px 6px 1px #1e413a;
  height: 100px;
}

.specific_page .ant-float-btn-primary {
  background-color: transparent;
}

.specific_page .ant-float-btn-square .ant-float-btn-body {
  height: 60px;
}

.specific_page
  .ant-float-btn-primary
  .ant-float-btn-body
  .ant-float-btn-content
  .ant-float-btn-description {
  font-size: 14px;
}

.specific_page .ant-btn {
  font-size: 13px;
}

.specific_page
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  color: rgb(46, 46, 46) !important;
}

.specific_page .ant-select-multiple .ant-select-selection-placeholder {
  color: rgb(46, 46, 46) !important;
}

.specific_page .CSR-Generator-Upload {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80px;
  margin-bottom: 10px;
  margin-top: 4px;
}

.specific_page .answer-content {
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  min-height: 150px;
  word-wrap: break-word;
}

.specific_page .GeneratedTitle {
  margin-top: 0;
  margin-bottom: 10px;
}

.specific_page .GeneratedTitleActive {
  background-color: #3388ff;
  width: 20%;
  color: whitesmoke;
  border-radius: 2px;
  padding: 2px;
}

.specific_page .ant-layout-content {
  padding: 10px !important;
}

.specific_page .ant-anchor-link-title {
  font-weight: 500;
  color: rgb(52, 52, 52) !important;
}

.specific_page
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 150px;
}

::-webkit-scrollbar {
  background-color: #fff !important;
  width: 16px !important;
}
::-webkit-scrollbar-track {
  background-color: #fff !important;
}
::-webkit-scrollbar-thumb {
  background-color: #babac0 !important;
  border-radius: 16px !important;
  border: 4px solid #fff !important;
}
::-webkit-scrollbar-button {
  display: none !important;
}

.specific_page .introcard {
  /* background: hsla(239, 100%, 67%, 1);

  background: linear-gradient(
    225deg,
    hsla(239, 100%, 67%, 1) 0%,
    hsla(187, 100%, 89%, 1) 90%
  );

  background: -moz-linear-gradient(
    225deg,
    hsla(239, 100%, 67%, 1) 0%,
    hsla(187, 100%, 89%, 1) 90%
  );

  background: -webkit-linear-gradient(
    225deg,
    hsla(239, 100%, 67%, 1) 0%,
    hsla(187, 100%, 89%, 1) 90%
  ); */

  background: -webkit-linear-gradient(
    153deg,
    rgb(51, 136, 255) 0%,
    hsl(0deg 0% 100%) 61%
  );

  /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#595cff", endColorstr="#c6f8ff", GradientType=1 ); */
}

.specific_page .active-link {
  background-color: blue;
  color: white; /* Adjust text color for better contrast */
}
.specific_page .active-link a {
  color: white; /* Ensure the link text inside the active link is also white */
}

.specific_page .ant-anchor-link-title-active {
  background-color: #3388ff !important;
  color: whitesmoke !important;
  margin-left: -2rem;
}

.specific_page .ant-collapse-header-text {
  font-weight: 500;
}

.specific_page .ant-collapse-item {
  margin-bottom: 22px !important;
}

.specific_page .ant-collapse-content-box {
  margin-top: -10px !important;
}

.specific_page .menuitem2 {
  display: flex;
  flex-direction: column;
  color: black;
  font-weight: 500;
  width: 100%;
  align-items: center;
  box-shadow: none;
  border-radius: 5px;
  background: transparent;
  padding: 5px;
}

.specific_page .menuitem2.active {
  box-shadow: 0px 0px 3px 0px #eceff7;
  background: #e0ebfe;
}

.specific_page .ant-upload-wrapper {
  width: 100%;
}

.specific_page
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 100%;
}

.specific_page .ant-upload-wrapper [class^="ant-upload"] {
  width: 100%;
}

.specific_page .ant-upload-list-item-container {
  width: 100% !important;
}

.specific_page .ant-collapse {
  font-size: 14.5px;
}

.specific_page .Source {
  font-weight: 500;
}

.specific_page
  .ant-steps.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-title {
  font-weight: 500;
}

.specific_page .UploadHeader {
  font-weight: 500 !important;
  margin-left: 12px;
  margin-bottom: -10px !important;
  margin-top: -10px !important;
  color: #6c6c6c;
}

.specific_page .ant-collapse-content-box {
  height: 50vh;
  overflow-y: auto;
}

.specific_page .ant-card-bordered_fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 111;
}

.specific_page .ant-layout .ant-layout-header {
  box-shadow: rgba(128, 128, 128, 0.13) 7px 0px 4px 3px;
  z-index: 1;
}

.specific_page .footer-note {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 97%;
  justify-content: center;
  padding: 2px;
  color: #686868;
}

.specific_page
  .section-action-button.ant-btn.ant-btn-icon-only.ant-btn-compact-item {
  border-color: #e3e3e3;
  background: #fafafa;
}

.specific_page .src-header .ant-card-meta-title,
.src-header .ant-card-meta-avatar {
  color: rgb(30, 150, 222);
}

.specific_page .ant-upload.ant-upload-select,
.ant-upload-list-item-container {
  height: 80px !important;
}

.specific_page .ant-upload-list-item-thumbnail.ant-upload-list-item-file {
  height: 80px !important;
}

.specific_page
  .ant-upload-list-item-thumbnail.ant-upload-list-item-file
  .anticon.anticon-file {
  font-size: large !important;
}

.specific_page Table.CSR_Table tr[data-row-key="2"] {
  display: none;
}

.specific_page tr.ant-table-row.ant-table-row-level-0[data-row-key="2"] {
  display: none;
}
