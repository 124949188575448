:root {
  --light-bg-primary: white;
  --light-text-primary: #0c0d21;

  --Dark-bg-primary: #0c0d21;
  --Dark-text-primary: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  background-color: #fff !important;
  width: 16px !important;
}
::-webkit-scrollbar-track {
  background-color: #fff !important;
}
::-webkit-scrollbar-thumb {
  background-color: #babac0 !important;
  border-radius: 16px !important;
  border: 4px solid #fff !important;
}
::-webkit-scrollbar-button {
  display: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.DarkMode .demo-logo {
  color: var(--Dark-text-primary);
  padding: 0px 23px;
  font-size: 1.5rem;
  font-weight: 600;
}

.demo-logo {
  color: var(--light-text-primary);
  padding: 0px 23px;
  font-size: 1.4rem;
  font-weight: 600;
}

.DarkMode .ant-layout-header {
  background: var(--Dark-bg-primary);
  color: var(--Dark-text-primary);
}

.ant-layout-header {
  height: 50px;
  padding: 0 45px;
  line-height: 50px;
  background: var(--light-bg-primary);
}

.DarkMode .ant-layout .ant-layout-sider-trigger {
  color: var(--Dark-text-primary);
  background: var(--Dark-bg-primary);
}

.ant-layout .ant-layout-sider-trigger {
  color: var(--light-text-primary);
  background: var(--light-bg-primary);
}

.DarkMode .ant-menu-light,
.DarkMode .ant-menu-light > .ant-menu {
  background: var(--Dark-bg-primary);
}

.ant-menu-light,
.ant-menu-light > .ant-menu {
  background: var(--light-bg-primary);
}

.DarkMode .ant-menu-light .ant-menu-item-selected,
.DarkMode .ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: white;
  background: #1677ff;
  /* background: rgb(5 41 102); */
  /* background-image: linear-gradient(310deg, #4d005c, rgb(30 150 222)); */
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  font-size: 0.8rem;
  color: white;
  background: #1677ff;
  /* background: rgb(5 41 102); */
  /* background-image: linear-gradient(310deg, #4d005c, rgb(30 150 222)); */
}

.DarkMode .ant-menu-light .ant-menu-item,
.DarkMode .ant-menu-light > .ant-menu .ant-menu-item,
.DarkMode .ant-menu-light .ant-menu-submenu-title,
.DarkMode .ant-menu-light > .ant-menu .ant-menu-submenu-title {
  color: var(--Dark-text-primary);
}

.DarkMode
  .ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover,
.DarkMode
  .ant-menu-light
  > .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: rgb(255 255 255 / 79%);
  transition-delay: 0.2s;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  font-weight: 600;
  font-size: 0.7rem;
  border-radius: 2px;
}

.ant-switch.ant-switch-checked,
.ant-switch {
  background: #4161c4;
}

.ant-menu .ant-menu-item .ant-menu-item-icon > *,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon > * {
  font-size: 17px;
}

.right-HeaderSection {
  display: flex;
  justify-content: end;
}

.Saama-logo {
  display: inline-block;
  height: 50px;
}

.Saama-logo img {
  height: 55px;
}

.ant-card .ant-card-head {
  min-height: 41px;
  font-size: 14px;
}

.ant-card-head {
  min-height: 56px;
  font-size: 15px;
}

.ant-layout .ant-layout-sider {
  background: #fff;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 150px;
}

.ant-select-selection-item {
  text-align: left;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom: 2px solid #1677ff;
}

.textArea {
  padding: 5px 15px 5px;
  font-weight: 400;
}

.ant-tabs .ant-tabs-tab-btn {
  font-weight: 500;
}

.HideUpload {
  display: none;
}

.SAP-Final-Toc-Actions {
  margin-right: 20px;
}

.ant-anchor-link-title {
  font-weight: 500;
  color: rgb(52, 52, 52) !important;
}

.site-description-item-profile-p-label {
  margin-bottom: 0px;
  margin-top: 5px;
  font-weight: 600;
}

.personalDetails {
  margin-bottom: 15px !important;
}

.ant-tabs-nav{
  margin: 0px !important;
}

.textarea.level1 {
  margin-left: 30px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: -3x;
}

.textarea.level2 {
  margin-left: 30px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: -3x;
}