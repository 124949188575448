.Doc-Diffrence-Upload {
  border: 1px solid #eceaea;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 31vh;
  margin-bottom: 10px;
}
