.PIL-Generator-Upload {
  border: 1px solid #f5f1f1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 55vh;
  margin-bottom: 10px;
}

/* .ActiveTab {
  background-color: rgba(0, 0, 0, 0.02);
  border-bottom: 2px solid #1677ff;
  border-radius: 3px;
} */
