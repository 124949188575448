.respDiv{
    border: 1px dotted #ccc;
    padding: 5px;
    border-radius: 4px;
    margin-top: 10px;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px dashed #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s;
}